.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentsContainer {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.paymentsContainer img {
  margin-top: 30px;
  width: 100px;
  height: auto;
}
