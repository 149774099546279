.mainCard {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  flex: 1;
}

.profile_wrapper {
  position: relative;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;

  .name_wrapper {
    margin-left: 2rem;

    p {
      margin: 0;
    }

    p:first-child {
      font-weight: 600;
      font-size: 1.3rem;
      color: rgba(0, 0, 0, 0.62);
    }

    p:nth-child(2) {
      font-size: 1.1rem;
      color: rgba(0, 0, 0, 0.42);
    }
  }

  .userAvatar {
    position: absolute;
    top: 0;
    right: 0;
    height: 160px;
    clip-path: circle(79px);
    object-fit: cover;
    object-position: center;
    margin-top: 10px;
    margin-right: 10px;
  }

  .userAvatar_default {
    position: absolute;
    top: 0;
    right: 0;
    height: 160px;
    clip-path: circle(79px);
    object-fit: cover;
    object-position: center;
    margin-top: 10px;
    margin-right: 10px;
  }
}

.company_wrapper {
  padding: 1rem 2rem;
  margin-top: 15px;

  p {
    margin: 0;

    &:first-child {
      font-size: 1.1rem;
      color: rgba(0, 0, 0, 0.42);
    }

    &:nth-child(2) {
      font-size: 1.3rem;
      color: rgba(0, 0, 0, 0.62);
      font-weight: 600;
    }
  }

  .company_logo_wrapper {
    .company_logo {
      padding-top: 5px;
      // height: 70px;
      width: 150px;
      object-fit: cover;
      object-position: center;
      border-radius: 14px;
    }
  }
}

.footer_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 1.5rem 2rem;

  .social_icons {
    display: flex;
    gap: 1rem;

    svg {
      fill: rgba(0, 0, 0, 0.62);
      width: 1.8rem;
      height: 1.8rem;
      transition: all 300ms;
      cursor: pointer;

      &:hover {
        fill: rgba(0, 0, 0, 1);
      }
    }
  }

  .arrow_to_profile {
    svg {
      fill: #5fad56;
      transition: all 300ms;
      cursor: pointer;

      &:hover {
        fill: #2dc653;
      }
    }
  }
}
