@import "../../styles/mixins.scss";

.container {
  background-color: #ffffff;
  box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
}

.custom {
  background-image: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );

  height: 20px;
  margin-bottom: 30px;
}

.memberName {
  text-align: center;
  margin-bottom: 20px;
  color: rgba(6, 147, 227, 1);
}

.memberStatus {
  text-align: center;
}

.membershipPeriod {
  text-align: center;
}

.daysLeft {
  text-align: center;
}

.addPeriod {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
