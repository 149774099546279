@import "styles/mixins.scss";

.pageContainer {
  padding: 0 55px 55px 55px;

  @include phone-only {
    padding: 0 20px 20px 20px;
  }

  .wideComponentWrapper {
    max-width: 1500px;
    margin: 0 auto;
  }

  .componentWrapper {
    // max-width: 1090px;
    margin: 0 auto;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block: 32px;

    @include desktop {
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
    }

    @include tablet-portrait {
      flex-direction: row;
      justify-content: space-between;
    }

    @include small-phone-only {
      flex-direction: column;
      // align-items: center;
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-shrink: 0;
      gap: 8px;

      .backTypography {
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
          color: #5b80ba;
        }

        .backSvg {
          margin-right: 5px;
          font-size: 1rem;
        }
      }

      .titleWrapper {
        display: flex;
        gap: 1rem;
        align-items: center;

        .toolTipIconWrapper {
          display: inline-flex;
          color: #ff4b55;
          margin-top: 0.5rem;

          .toolTipSvg {
            font-size: 22px;
          }
        }
      }
    }

    .actionAreaContainer {
      display: flex;
      align-items: center;
      gap: 16px;
      padding-left: 32px;

      @include desktop {
        padding-left: 0;
      }

      @include tablet-portrait {
        flex-direction: column;
        align-items: initial;
      }
    }
  }
}
