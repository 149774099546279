@import "../../styles//mixins.scss";

.mainContainer {
  background-color: red;
}

.table {
  margin-bottom: 30px;
}

.chartData {
  width: 400px;
  height: 400px;
  margin: 0 auto;

  @include small-phone-only {
    width: 100%;
    height: 100%;
  }
}

.filterData {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
