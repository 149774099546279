//Fonts imports
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap");

//Mixins import
@import "styles/mixins.scss";

//Component imports
@import "lib/components/Buttons/buttons.scss";

body {
  margin: 0;
  background-color: #f4f5f7;
  font-family: "Montserrat";
}

* {
  box-sizing: border-box;
}

// Styles for the arrow button from table row
.details-arrow-style {
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: #5b80ba;
      }
    }
  }
}
