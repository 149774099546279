.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .rowContainer {
    display: flex;
    gap: 1rem;
    align-items: center;

    .delete {
      fill: rgba(0, 0, 0, 0.3);
    }

    .deleteActive {
      cursor: pointer;
      fill: #ff4b55;

      &:hover {
        fill: #a50009;
      }
    }
  }
}
