@import "../../styles/mixins.scss";

.companyWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: #dfe2e6 0px 4px 16px, #dfe2e6 0px 8px 32px;
  margin-top: 2rem;
  padding: 16px;
  max-width: min-content;

  .imageBox {
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageContent {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 0.5rem;

    .infoRow {
      display: flex;
      gap: 0.5rem;

      .site {
        text-decoration: underline;
        word-break: break-all;
        hyphens: auto;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.62);

        &:hover {
          color: #5b80ba;
          cursor: pointer;
        }
      }

      .description {
        hyphens: auto;
        max-height: 15rem;
        overflow: auto;
      }
    }
  }

  .buttonsWrapper {
    display: flex;
    gap: 1rem;
    margin-top: auto;
    justify-content: center;
    margin-bottom: 1rem;
    padding-top: 1rem;
  }

  .companyButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
  }
}
