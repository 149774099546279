.ticketContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 15px;
  // width: 300px;
  box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
  gap: 15px;
}

.ticketInfoContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px grey dotted;
  margin-bottom: 10px;
  word-wrap: break-word;
  word-break: break-all;
}

.qrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.editButtonStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.editTicketContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
