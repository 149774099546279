.actionArea {
  display: flex;
  gap: 10px;
}

.seeDetailsButton {
  color: black;
  border-radius: 3px;
}

.scanButton {
  color: red;
  cursor: pointer;
}
