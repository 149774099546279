@import "../../styles/mixins.scss";

.container {
  border-radius: 20px;
  border: 2px solid #eeeeee;
  background: #ffffff;
  box-shadow: #dfe2e6 0px 4px 16px, #dfe2e6 0px 8px 32px;
  // min-height: 65vh;
}

.formWrapper {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.textFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.title {
  color: black;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 0.15px;
  font-style: normal;
}

.description {
  padding: 0 2rem;
  margin: 0 auto;
}

.actionAreaIndividualEducation {
  display: flex;
  align-items: right;
  justify-content: flex-end;
}

.tooltip {
  margin-top: 7px;
}
