.mainContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  border-top: 1px solid black;
  background-color: #ffffff;
  margin-top: 100px;
}

.textContent {
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.62);
}
