@import "styles/mixins.scss";

.mainContainer {
  padding: 0 55px 55px 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  @include small-phone-only {
    padding: 20px;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage {
  width: 200px;
  height: auto;
}
