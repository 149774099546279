.qrAdminPageContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.eventParticipantsContainer {
  display: flex;
  flex-direction: column;
  // gap: 16px;
  border: 1px solid black;
}

.attendanceModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  // align-items: center;
  text-align: center;
}

.exMemberContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.userStyle {
  border-radius: 16px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #5b80ba;
}

.userStatus {
  border-radius: 16px;
  padding: 10px;
  color: white;
}

// form for adding membership period when user is inactive

.formNewPeriodModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.guestContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

//custom events

.eventCustomContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
