.individualParticipant {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c8c7cc;
  padding: 10px 15px;
}

.userNotAttended {
  border: 1px solid #c8c7cc;
}
.userAttended {
  border: 3px solid green;
}

.deleteParticipant {
  background-color: #d9534f;
  border-radius: 16px;
  color: white;
  display: flex;
  cursor: pointer;
  :hover {
    background-color: #d9574f;
    border-radius: 16px;
  }
}
.mainContainer {
  display: flex;
  flex-direction: column;
  height: 50vh;
}

.scrollable {
  flex: 1;
  overflow-y: auto;
}

.noMembers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.attendeesPresenceStyle {
  margin-bottom: 15px;
}
