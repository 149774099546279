@import "styles/mixins.scss";

.tableCard {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  padding: 24px 16px;
  background-color: #ffffff;
  box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
}

.cardHeader {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  hyphens: auto;
}

.headerText {
  font-size: 0.875rem;
  font-weight: bold;
  color: #6b7280;
  @include phone-only {
    font-size: 0.75rem;
  }
}

.columnData {
  max-width: 142px;
  justify-self: end;
  text-align: end;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;

  @include phone-only {
    font-size: 0.75rem;
  }
}
