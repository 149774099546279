@import "styles/mixins.scss";

.mainContainer {
  display: flex;
  gap: 16px;
  @include small-phone-only {
    flex-direction: column;
    align-items: center;
  }
}

.leftContainer {
  width: 50%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  // border: 1px solid green;

  @include small-phone-only {
    width: 100%;
  }
}

.upperLeft {
  //   height: 6vh;
}

.downLeft {
  flex: 1;
  overflow-y: auto;
}

.rightContainer {
  width: 50%;
  @include small-phone-only {
    width: 100%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
