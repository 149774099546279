.mainContainer {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.firstImg {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  filter: blur(10px);
  transition-property: all;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
}

.opacity0 {
  opacity: 0;
}

.secondImg {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  transition-property: all;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
}
