@import "../../styles/mixins.scss";

.profileHeader {
  background-color: #ffffff;
  width: 100%;
  // height: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  box-shadow: #eaeef4 0px 4px 16px -16px, #eaeef1 0px 8px 32px -32px;
  gap: 1rem;

  .text {
    padding: 0;

    @include phone-only {
      padding: 0 1rem;
    }
  }

  .tabs {
    &.MuiButtonBase-root {
      padding: 12px 16px;
      @include phone-only {
        padding: 12px 8px;
      }
    }

    .tab {
      font-family: "Montserrat", sans-serif;
      line-height: 17px;
      font-weight: 600;
      font-size: 14px;
      font-style: normal;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 0.62);

      @include phone-only {
        font-size: 12px;
        text-transform: none;
      }
    }
  }
}

.settingWrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 1090px;
  margin: 0 auto;

  @include phone-only {
    padding: 0;
  }

  .profileWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin: 1rem 0 2rem 0;

    .profileContainer {
      display: flex;
      gap: 2rem;
      border-radius: 20px;

      @include tablet-portrait {
        flex-direction: column;
      }

      @include phone-only {
        padding: 1rem;
      }

      .profileColumn {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-shrink: 0;
        border-radius: 20px;
        padding: 15px 10px;
        align-items: center;

        &:not(:last-child) {
          background-color: #ffffff;
          box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
          padding: 15px 30px;
        }

        @include tablet-portrait {
          width: 100%;
          flex-shrink: 1;
        }

        .imageWrapper {
          display: flex;
          flex-direction: column;
          margin: 0 auto;

          .text {
            font-size: 14px;
            font-weight: 600;
            color: black;
            opacity: 62%;
            text-align: center;
          }

          .imageWrapperContainer {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            .avatar {
              width: 130px;
              height: 130px;
              border: 3px solid lightgrey;
              box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
            }
          }
        }

        .gridContainer {
          display: grid;
          // grid-template-columns: 1fr 1fr;

          @include phone-only {
            // grid-template-columns: 1fr 2fr;
          }

          .gridElement {
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .underline {
              border-bottom: 1px solid lightgrey;
              width: 100%;
            }
          }

          .breakWord {
            word-break: break-all;
          }
        }
      }

      .buttonWrapper {
        margin: 1rem auto;
      }
    }

    .columnDetails {
      width: calc(70% - 1rem);
    }

    .columnPicture {
      width: calc(30% - 1rem);
    }
  }

  .companiesWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin: 1rem 0 2rem 0;

    .companiesHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include phone-only {
        flex-direction: column;
        gap: 15px;
      }
    }

    .companiesContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      border-radius: 20px;
      margin: 0 auto;
      width: 100%;
      justify-content: center;

      @include phone-only {
        justify-content: center;
      }
    }
  }

  .actionsWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 1rem;

    .passwordWrapper {
      border-radius: 20px;
      background-color: #ffffff;
      box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
      width: 100%;
      padding: 2rem;
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include tablet-portrait {
        flex-direction: column;
      }

      .actionInfo {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include tablet-portrait {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.changeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @include phone-only {
    flex-direction: column;
    padding-top: 0.8rem;
  }

  .changeLeft {
    width: 60%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    input[type="file"] {
      display: none;
    }
    .customFileUpload {
      border: 1px solid #ccc;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
      width: 252px;
      height: 52px;
      background-color: #5b80ba;
      color: #ffffff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include phone-only {
      align-items: center;
      width: 100%;
    }
  }

  .changeRight {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include phone-only {
      // width: 80%;
      // margin: 0 auto;
      align-items: center;
    }
  }
}

.changeWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formWrapper {
  display: flex;
  justify-content: space-between;
  gap: 3rem;

  @include phone-only {
    flex-direction: column;
  }

  .textFieldsWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .avatar {
      width: 130px;
      height: 130px;
      border: 3px solid lightgrey;
      // box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
    }
  }
}

.formWrapperChangeMemberInformation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
}

.deleteAccountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.membershipCard {
  // display: flex;
  margin: 0 auto;
  width: 100%;
  // align-items: center;
  // justify-items: center;
  background-color: red;
}

.customCompanyImageUpload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
  height: 52px;
  background-color: #5b80ba;
  color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
