@import "styles/mixins.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.firstContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @include small-phone-only {
    flex-direction: column;
  }
}

.secondContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @include small-phone-only {
    flex-direction: column;
  }
}
