.mainContainer {
  background-color: #ffffff;
  width: 500px;
  height: auto;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.becomeMemberButton {
  background-color: #5b80ba;

  :hover {
    background-color: red;
  }
}

.priceInfo {
  background-color: #5b80ba;
  width: 100%;
  height: auto;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.benefitsInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.listOfBenefits {
  list-style-type: none;
}

.checkedIcon {
  margin-right: 6px;
}
