@import "styles/mixins.scss";

.modal {
  display: flex;
  flex-direction: column;
  height: 60vh;
}

.scrollable {
  flex: 1;
  overflow-y: auto;
}

.static {
  margin-top: 16px;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  @include small-phone-only {
    height: 10vh;
    flex-direction: column;
  }
}

.enrollDate {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #31708f;
}
.eventsParticipation {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #3c763d;
}
.eventsAsMember {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #8a6d3b;
}

.divder {
  border: 1px solid #ddd;
  height: 50%;
}
.rowMainWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  margin-top: 10px;
  padding: 5px;

  @include small-phone-only {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.leftSide {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 30%;

  @include small-phone-only {
    width: 100%;
  }
}
.rightSide {
  width: 70%;
}

.downSide {
  background-color: #5cb85c;
  border-radius: 10px;
  color: white;
  padding: 2px;
}
