@import "styles/mixins.scss";

.emailAction {
  background-color: #555555;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  width: fit-content;
}
.editAction {
  background-color: #f0ad4e;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  width: fit-content;
}

.seeParticipantsAction {
  background-color: #337ab7;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  width: fit-content;
}

.deleteEvent {
  background-color: #d9534f;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  width: fit-content;
}

.openEvent {
  background-color: #007aff;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  width: fit-content;
}

.lineContainer {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  background-color: #5cb85c;
  z-index: 1;
}

.mainContainer {
  position: relative;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 16px;
  &::before {
    content: "";
    position: absolute;
    top: 16px;
    left: -48px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #5cb85c;
    @include small-phone-only {
      display: none;
    }
  }
}

.contentContainer {
  padding-top: 0.6rem;
  line-height: 1.5;
}

.dateTimeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

.locationContainer {
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.descriptionContainer {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  word-break: break-word;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 3px;
}

.participantButtons {
  display: flex;
  gap: 10px;
  @include small-phone-only {
    flex-direction: column;
  }
}

.numberOfParticipantsStyle {
  color: #31708f;
  font-weight: 400;
  font-size: 90%;
  margin-top: 0px;
}

.participantStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #5cb85c;
  color: #ffffff;
  border-radius: 8px;
  padding: 6px 14px;
  height: 2rem;
}

.participantsIcon {
  background-color: #5cb85c;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  width: fit-content;
}
