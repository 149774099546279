@import "styles/mixins.scss";
.mainContainer {
  position: relative;
  padding-top: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  @include small-phone-only {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  border-radius: 20px;
}

.mainContainer::after {
  content: "";
  position: absolute;
  top: 0;
  // left: 50%;
  width: 1px;
  background-color: #5cb85c;
  z-index: 1;
  transform: translateX(-50%);
  height: 100%;
  @include small-phone-only {
    display: none;
  }
}

.childrenContainer {
  padding: 2rem;
  @include small-phone-only {
    padding: 0rem;
  }
}
