@import "styles/mixins.scss";

.event {
  border: 4px solid transparent;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px #eaeef4, 0px 8px 32px #eaeef1;
}

.active {
  border-bottom: 4px solid #42d422;
  background-color: rgba(115, 109, 109, 0.1);
}

.inactive {
  display: none;
}

.eventHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eventEdit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.eventEditBtn {
  color: #5b80ba;
  cursor: pointer;
}

.eventRemove {
  color: #f23b2f;
  cursor: pointer;
}

.eventTitle {
  color: #5b80ba;
}

.eventDescription {
  color: rgba(0, 0, 0, 0.62);
  margin-bottom: 40px;
}

.eventFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #5b80ba;
  @include small-phone-only {
    flex-direction: column;
    gap: 20px;
  }
}

.eventEditBtn:hover {
  color: #2d4f87;
  transform: scale(1.2);
  transition: all 0.1s ease-in;
}

.eventRemove:hover {
  transform: scale(1.2);
  color: #ac2117;
  transition: all 0.1s ease-in;
}

.eventButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.loadingProduct {
  color: rgba(0, 0, 0, 0.62);
  text-align: center;
  font-size: 48px;
  margin-top: 100px;
}

.formWrapperChangeMemberInformation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.actionAreaButtons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @include small-phone-only {
    flex-direction: column;
  }
  @include tablet-portrait {
    flex-direction: column;
  }
}
