@import "styles/mixins.scss";

.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #5cb85c;
  border-radius: 16px;
  padding: 16px;

  @include small-phone-only {
    display: flex;
    flex-direction: column;
  }
}

.mainWrapperRow {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #007aff;
  border-radius: 16px;
  padding: 16px;

  @include small-phone-only {
    display: flex;
    flex-direction: column;
  }
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007aff;
}

.membershipWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @include small-phone-only {
    display: flex;
    flex-direction: column;
  }
}

.addPaymentButton {
  border: 1px solid #5cb85c;
  border-radius: 10px;
  color: white;
  background-color: #5cb85c;
  display: flex;
  cursor: pointer;
  :hover {
    border-radius: 10px;
    background-color: red;
  }
}

.editPaymentButton {
  border: 1px solid #46b8da;
  border-radius: 10px;
  color: white;
  background-color: #46b8da;
  display: flex;
  cursor: pointer;
  :hover {
    border-radius: 10px;
    background-color: #3891ac;
  }
}

.deletePaymentButton {
  border: 1px solid #d43f3a;
  border-radius: 10px;
  color: white;
  background-color: #d43f3a;
  display: flex;
  cursor: pointer;
  :hover {
    border-radius: 10px;
    background-color: #b95334;
  }
}

.editButtons {
  display: flex;
  gap: 10px;
}

.datesContainer {
  display: flex;
  gap: 10px;
  @include small-phone-only {
    flex-direction: column;
  }
}

.loadingWrapper {
  height: 5rem;
}

.noPayments {
  text-align: center;
  margin-top: 20px;
}
