@import "styles/mixins.scss";
.mainContainer {
  display: flex;
  justify-content: space-between;
}
.loadMoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
