@import "styles/mixins.scss";

.wrapper {
  margin-bottom: 4rem;
}

.mainContainer {
  padding: 0rem 0 1rem 0;
  display: flex;
  // justify-content: space-between;
  gap: 16px;
  @include small-phone-only {
    flex-direction: column;
  }
  @include tablet-portrait {
    flex-direction: column;
  }
  @include tablet-landscape {
    flex-direction: column;
  }
  // background-color: pink;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // background-color: red;
  width: 50%;
  @include tablet-landscape {
    width: 100%;
  }
}

.upperFirst {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: green;
}
.userPhotoContainer {
  display: flex;
  justify-content: center; /* center the child element horizontally */
  align-items: center; /* center the child element vertically */
  border-radius: 16px;
  margin-bottom: 16px;
  height: 300px;
  max-width: 100%; /* set a fixed size */
  max-height: 100%; /* set a fixed size */
}

.userPhoto {
  max-width: 100%;
  max-height: 100%; /* adjust height to maintain aspect ratio */
  object-fit: cover; /* make the image fill the container */
  object-position: center; /* center the image within the container */
  border-radius: 16px;
}

.downFirst {
  //   background-color: green;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.companiesContainerDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.secondContainer {
  // display: flex;
  // flex-direction: column;
  // background-color: yellow;
  // gap: 16px;

  h4 {
    font-size: 1.2rem;
    margin: 0 0 0.8rem 0;
  }

  width: 50%;

  @include tablet-landscape {
    width: 100%;
  }
}

.upperSecond {
  background-color: white;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  height: fit-content;
  padding: 16px;

  h5 {
    &:first-child {
      margin: 0 0 0.5rem 0;
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.52);
    }
  }

  .info {
    margin-bottom: 1rem;
  }
}
.companyDetails {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-radius: 16px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;

  @include tablet-portrait {
    gap: 2rem;
  }

  @include small-phone-only {
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    gap: 2rem;
  }
}

.companyInfos {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;

  // background-color: green;
  // width: 50%;

  @include tablet-landscape {
    grid-template-columns: 1fr;
  }

  @include tablet-portrait {
    grid-template-columns: 1fr;
  }
}
.companyLogoContainer {
  width: 250px;
  height: auto;
}

.companyLogo {
  width: 250px;
  height: auto;
  border-radius: 12px;
}

.companyDetailsIcons {
  display: flex;
  align-items: center;
  // justify-content: start;
  gap: 16px;

  svg {
    width: 2rem;
    height: 2rem;
    fill: rgba(0, 0, 0, 0.42);
  }
}

.companyDescription {
  display: flex;
  align-items: start;
  gap: 16px;

  svg {
    width: 2rem;
    height: 2rem;
    fill: rgba(0, 0, 0, 0.42);
  }
}

.firstSectionCompany {
  width: 100%;
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  gap: 20px;
  @include small-phone-only {
    flex-direction: column;
    align-items: center;
  }
}

// .companyDetailsIcons > div {
//   width: 150px; /* add a fixed width to the text content */
// }

.site {
  text-decoration: underline;
  word-break: break-all;
  hyphens: auto;
  color: rgba(0, 0, 0, 0.62);

  &:hover {
    color: #5b80ba;
    cursor: pointer;
  }
}
