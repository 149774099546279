@import "../../../styles/mixins.scss";
.raisis-crm-logo {
  height: 39px;
}

.app_bar_container {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  @include phone-only {
    // justify-content: flex-end;
    justify-content: space-evenly;
  }

  .app_bar_raisis_logo {
    width: 158px;
    height: 49px;
    cursor: pointer;
    margin-left: 4rem;

    @include hd {
      margin-left: 1rem;
    }

    @include phone-only {
      display: none;
    }
  }

  .app_bar_darex_logo {
    width: 300px;
    height: auto;

    @include tablet-portrait {
      width: 250px;
      height: auto;
    }
  }

  .company_logo {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    line-height: 34px;
    font-weight: 700;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.62);
  }

  .app_bar_info_container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .app_bar_user_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-right: 4rem;
    padding: 10px;

    @include hd {
      margin-right: 0;
    }

    .app_bar_user_image {
      background-color: #bdbdbd;
      border-radius: 50%;
      margin-right: 1rem;
    }

    .app_bar_user_name {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: none;
      color: #929baa;
    }
  }

  .app_bar_hamburger_menu {
    display: none;

    @include hd {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .app_bar_menu_icon {
      font-size: 2rem;
    }
  }
}
