.checkboxGroupWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .checkboxGroup {
    display: flex;
    gap: 16px;

    label {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.62);

      input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 0;
      }

      input[type="checkbox"] {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: red;
        width: 16px;
        height: 16px;
        border: 2px solid #e9e9e9;
        border-radius: 3px;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
      }

      input[type="checkbox"]::before {
        content: "";
        width: 8px;
        height: 8px;
        transform: scale(0);
        border-radius: 2px;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 10px 10px #5b80ba;
      }

      input[type="checkbox"]:checked::before {
        transform: scale(1);
      }
    }

    .gdpr {
      text-decoration: underline;
      word-break: break-all;
      hyphens: auto;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 0.62);

      &:hover {
        color: #5b80ba;
        cursor: pointer;
      }
    }
  }

  .checkboxGroupError {
    color: #ff4b55;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
  }
}
