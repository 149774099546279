@import "../../styles/mixins.scss";

.newsContainer {
  width: 100%;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: #dfe2e6 0px 4px 16px, #dfe2e6 0px 8px 32px;
  padding: 16px;

  .userSection {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .infoSection {
      display: flex;
      align-items: center;
      gap: 2rem;

      @include phone-only {
        flex-direction: column;
        align-items: start;
        gap: 1rem;
      }

      .paragraphInfo {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.2px;
        color: rgba(0, 0, 0, 1);

        span {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.9);
        }

        @include phone-only {
          margin: 0;
        }
      }
    }

    .actionsSection {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      @include phone-only {
        align-items: start;
      }
    }
  }

  .newsSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      margin: 1rem 0 0 0;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 1);
    }

    .description {
      width: 100%;

      iframe {
        width: 100%;
        border-radius: 20px;
        border: 2px solid #eeeeee;
        box-shadow: inset #dfe2e6 0px 1px 4, #eaeef1 0px 2px 8px;
      }
    }

    .button {
      margin: 0 auto;
    }
  }
}
