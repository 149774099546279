@import "../styles/mixins.scss";

.wrapper {
  position: fixed;
  width: 100%;
  height: 6rem;
  top: 0;
  left: 0;
  z-index: 100;

  .container {
    position: fixed;
    width: 15rem;
    height: calc(100vh - 6rem);
    top: 6rem;
    left: 0;
    background-color: #ffffff;
    z-index: 50;

    @include hd {
      height: 100vh;
      top: 0;
    }
  }
}
