@import "styles/mixins.scss";

//User css
.midWrapper {
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: row;

  gap: 1rem;

  @include special-sections {
    flex-direction: column;
    align-items: center;
  }

  .midOne {
    height: fit-content;
    max-width: 500px;
    flex-shrink: 0;
    background-color: #ffffff;
    box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
    border-radius: 1rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    //margin: auto;

    .oneTop {
      display: flex;
      justify-content: space-between;

      @include small-phone-only {
        flex-direction: column;
        gap: 2rem;
      }

      .imageContainerUser {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.7rem;
        width: 250px;

        .profileAvatar {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          border: 3px solid lightgrey;
          box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            object-position: center;
          }
        }
      }

      .infoDetailsUsers {
        width: 250px;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        @include small-phone-only {
          align-items: center;
        }

        .oneLeftBrands {
          display: flex;
          flex-direction: row;
        }

        .divider {
          width: 40%;
        }
      }
    }

    .oneBottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.75rem;
      margin: 0 auto;
      max-width: 350px;

      @include small-phone-only {
        margin-top: 1rem;
        flex-direction: column;
      }
    }
  }

  .midTwo {
    // width: calc(100% - 500px);

    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 16px;
    box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
    @include special-sections {
      width: 100%;
    }
    @include small-phone-only {
      align-items: center;
      justify-content: center;
    }

    .bottomWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }
  }
}

//Edit user css
.formComponent {
  height: 100%;
  margin-top: 10px;

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .formContainer {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-bottom: 1rem;

      @include tablet-portrait {
        flex-direction: column;
      }

      .formColumn {
        width: calc(50% - 8px);
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;

        @include tablet-portrait {
          width: 100%;
        }

        .columnContainer {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    }

    .imageWrapper {
      display: flex;
      flex-direction: column;

      .text {
        font-size: 14px;
        font-weight: 600;
        color: black;
        opacity: 62%;
      }

      .imageWrapperContainer {
        padding-top: 1rem;
        display: flex;
        align-items: center;
        gap: 2rem;

        .avatar {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

//dot used to distinct a user from his brands
.dot {
  width: 5px;
  height: 5px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 2px;
  display: inline-block;
  vertical-align: middle;
}

//Add user modal
.addUserWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4rem;

  @include tablet-portrait {
    flex-direction: column;
    gap: 1rem;
  }

  .formColumn {
    width: calc(50% - 2rem);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include tablet-portrait {
      width: 100%;
    }

    .imageWrapper {
      display: flex;
      flex-direction: column;

      .textImage {
        font-size: 14px;
        font-weight: 600;
        color: black;
        opacity: 62%;
      }

      .imageContainer {
        padding-top: 1rem;
        display: flex;
        align-items: center;
        gap: 2rem;

        .avatar {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}

//Users table styles
.userNameColumn {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.actionArrow {
  //   width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.seeDetailsButton {
  background-color: #f0ad4e;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.seeCompanies {
  background-color: #337ab7;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  //   width: fit-content;
}

.seePeriod {
  background-color: #5bc0de;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.deleteMember {
  background-color: #d9534f;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.seeEvents {
  background-color: #007aff;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.observationsRow {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.userCards {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include small-phone-only {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
}

.loadMoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin: 20px 20px;
}

.loadingWrapper {
  height: 5rem;
}

.companiesDisplayTable {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.changeType {
  background-color: green;
  padding: 0.4em 0.6em;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
