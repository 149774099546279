@import "styles/mixins.scss";

.mainContainer {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  // grid-template-columns: repeat(8, 1fr);
  grid-auto-flow: column;
  gap: 20px;
  @include small-phone-only {
    grid-auto-flow: row;
  }
}
