@import "styles/mixins.scss";
.actionButtons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.addResource {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.actionAreaIndividualEducation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.youtubePlayer {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.youtubePlayer iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.mainPageContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.descriptionContainer {
  background-color: #ffffff;
  box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
  padding: 16px;
  border-radius: 16px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.invalidYoutubeLink {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
  padding: 16px;
  border-radius: 16px;
}

.youtubeLinkContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wrapperTableCards {
  @include tablet-landscape {
    display: flex;
    flex-direction: column;
  }
  @include tablet-landscape {
    gap: 16px;
  }
  @include tablet-portrait {
    display: flex;
  }
}
