@import "styles/mixins.scss";
.mainContainerParticipants {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c8c7cc;
  padding: 10px 15px;
  @include small-phone-only {
    flex-direction: column;
  }
}

.participantsListContainer {
  height: 400px; /* Set a fixed height for the container */
  overflow-y: auto; /* Add scrollbar if content overflows */
}

.participantsList {
  display: flex;
  flex-direction: column;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 50vh;
}

.scrollable {
  flex: 1;
  overflow-y: auto;
}

.noMembers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}
