@import "styles/mixins.scss";

.companyLogo {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  line-height: 34px;
  font-weight: 700;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.62);
}

.boxWidth {
  width: 40vw;

  @include tablet-landscape {
    width: 50vw;
  }

  @include phone-only {
    width: 90vw;
  }
}

.boxSignUpWidth {
  @include big-desktop {
    width: 60vw;
  }

  @include tablet-landscape {
    width: 80vw;
  }
}

.allRights {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2rem;
  letter-spacing: 0.1px;

  span {
    font-weight: 600;
    letter-spacing: 0.2px;
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.buttonsWrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  @include small-phone-only {
    flex-direction: column;
  }
}

.buttonsWidthFull {
  > button {
    width: 100%;
  }
}

.buttonsWidth40 {
  > button {
    width: 40%;

    @include tablet-landscape {
      width: 100%;
    }
  }
}

.formComponent {
  height: 100%;

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .formContainer {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      @include tablet-portrait {
        flex-direction: column;
      }

      .formColumn {
        width: calc(50% - 8px);
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;

        @include tablet-portrait {
          width: 100%;
        }

        .columnContainer {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    }

    .imageWrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      &:first-child {
        margin-bottom: 2rem;
      }

      .text {
        font-size: 14px;
        font-weight: 600;
        color: black;
        opacity: 62%;
        text-align: center;
      }

      .imageWrapperContainer {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .avatar {
          width: 130px;
          height: 130px;
          border: 3px solid lightgrey;

          box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
        }

        .clientText {
          text-decoration: underline;
          &:hover {
            color: #5b80ba;
            cursor: pointer;
          }
        }
      }
    }
  }
}
